export const USER_DATA = {
    helderpedro: {
      fullName: 'Helder Pedro',
      medicalQuestion: 'Qual é a principal função da vitamina D no organismo?',
      answer: ['Absorção de cálcio', 'Saúde óssea'],
      table: 'Tabela 1',
      hints: ['É sintetizada pela pele através da luz solar.', 'É crucial para ossos fortes.', 'Relacionada com o metabolismo do cálcio.']
    },
    anapedro: {
      fullName: 'Ana Pedro',
      medicalQuestion: 'Qual é a principal função do sistema linfático?',
      answer: ['Defesa do organismo', 'Transporte de linfa'],
      table: 'Tanzânia',
      hints: ['Está relacionado com a imunidade.', 'Transporta linfa e células do sistema imunitário.', 'Ajuda na drenagem de líquidos.']
    },
    filipapedro: {
      fullName: 'Filipa Pedro',
      medicalQuestion: 'Qual é a principal função das plaquetas sanguíneas?',
      answer: ['Coagulação sanguínea'],
      table: 'Tabela 3',
      hints: ['São células sanguíneas.', 'Essenciais para a cicatrização de feridas.', 'Previnem hemorragias.']
    },
    mariamarques: {
      fullName: 'Maria Marques',
      medicalQuestion: 'O que causa a miastenia grave?',
      answer: ['Anticorpos contra receptores de acetilcolina'],
      table: 'Tabela 4',
      hints: ['Afeta a comunicação entre nervos e músculos.', 'É uma doença autoimune.', 'Causa fraqueza muscular.']
    },
    antoniopedro: {
      fullName: 'Antonio Pedro',
      medicalQuestion: 'Qual é a função do fígado na metabolização de medicamentos?',
      answer: ['Desintoxicação', 'Metabolismo de fármacos'],
      table: 'Tabela 5',
      hints: ['É o principal órgão envolvido na desintoxicação do corpo.', 'Transforma substâncias em compostos menos tóxicos.', 'Responsável por metabolizar muitos medicamentos.']
    },
    luciliapedro: {
      fullName: 'Lucilia Pedro',
      medicalQuestion: 'Qual é a diferença entre a respiração pulmonar e a respiração celular?',
      answer: ['Respiração pulmonar é troca de gases', 'Respiração celular é produção de ATP'],
      table: 'Tabela 6',
      hints: ['A respiração pulmonar ocorre nos pulmões.', 'A respiração celular ocorre nas mitocôndrias.', 'A respiração celular gera energia.']
    },
    dorapedro: {
      fullName: 'Dora Pedro',
      medicalQuestion: 'O que é a síndrome de Cushing?',
      answer: ['Excesso de cortisol', 'Hipercortisolismo'],
      table: 'Tabela 7',
      hints: ['Pode ser causado por tumores na glândula suprarrenal.', 'Os sintomas incluem ganho de peso e fraqueza muscular.', 'Está relacionada com a produção excessiva de cortisol.']
    },
    ruibarquina: {
      fullName: 'Rui Barquina',
      medicalQuestion: 'Qual é o principal fator de risco para o desenvolvimento de arteriosclerose?',
      answer: ['Hipertensão', 'Tabagismo', 'Hiperlipidemia'],
      table: 'Tabela 8',
      hints: ['A hipertensão danifica as artérias.', 'O tabagismo acelera o processo de endurecimento das artérias.', 'Elevados níveis de colesterol também são um fator de risco.']
    },
    hugopedro: {
      fullName: 'Hugo Pedro',
      medicalQuestion: 'Qual é a função da insulina no metabolismo?',
      answer: ['Reduz os níveis de glicose no sangue', 'Facilita a entrada de glicose nas células'],
      table: 'Tabela 9',
      hints: ['É produzida pelo pâncreas.', 'Está relacionada com a regulação da glicose.', 'O seu défice está associado ao diabetes tipo 1.']
    },
    susetealmeida: {
      fullName: 'Susete Almeida',
      medicalQuestion: 'Qual é a principal causa do infarto do miocárdio?',
      answer: ['Obstrução das artérias coronárias', 'Aterosclerose'],
      table: 'Tabela 10',
      hints: ['A obstrução pode ser causada por placas de gordura.', 'O infarto ocorre quando o fluxo sanguíneo para o coração é bloqueado.', 'Está frequentemente associado a fatores como tabagismo e hipertensão.']
    },
    davidpedro: {
      fullName: 'David Pedro',
      medicalQuestion: 'O que caracteriza a doença de Alzheimer?',
      answer: ['Acúmulo de placas de proteína beta-amiloide', 'Perda de memória', 'Declínio cognitivo'],
      table: 'Tabela 11',
      hints: ['É uma doença neurodegenerativa.', 'Afeta principalmente idosos.', 'Está associada a falhas na comunicação entre células nervosas.']
    },
    carlosromana: {
      fullName: 'Carlos Romana',
      medicalQuestion: 'Qual é o papel dos glóbulos vermelhos no transporte de oxigénio?',
      answer: ['Transportam oxigénio dos pulmões para os tecidos'],
      table: 'Tabela 12',
      hints: ['Contêm hemoglobina, que se liga ao oxigénio.', 'São células sem núcleo.', 'Facilitam a troca gasosa.']
    },
    elisabete: {
      fullName: 'Elisabete',
      medicalQuestion: 'Qual é a principal função do sistema endócrino?',
      answer: ['Regulação hormonal'],
      table: 'Tabela 13',
      hints: ['Composto por glândulas que produzem hormonas.', 'Controla funções como o crescimento e o metabolismo.', 'Está envolvido em processos como o stress e a reprodução.']
    },
    eduardoromana: {
      fullName: 'Eduardo Romana',
      medicalQuestion: 'Quais são os fatores de risco para o desenvolvimento de diabetes tipo 2?',
      answer: ['Obesidade', 'Sedentarismo', 'Histórico familiar'],
      table: 'Tabela 14',
      hints: ['A obesidade aumenta a resistência à insulina.', 'A falta de exercício contribui para o aumento de glicose no sangue.', 'O histórico familiar pode aumentar a predisposição para a doença.']
    },
    miguelromana: {
      fullName: 'Miguel Romana',
      medicalQuestion: 'Qual é a principal função dos rins?',
      answer: ['Filtração de resíduos', 'Regulação do equilíbrio hídrico e eletrolítico'],
      table: 'Tabela 15',
      hints: ['Produzem urina para eliminar resíduos.', 'Regulam a quantidade de água no corpo.', 'São fundamentais para a homeostase.']
    }
  };
  