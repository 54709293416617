import { Component, OnInit, AfterViewInit } from '@angular/core';

import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { USER_DATA } from './userdata';
declare var $: any;

@Component({
  selector: 'app-entourage',
  templateUrl: './entourage.component.html',
  styleUrls: ['./entourage.component.css']
})
export class EntourageComponent implements OnInit, AfterViewInit {

  debugActive = true;

  invitee: any = null;
  userAnswer: string = '';
  showHints: boolean = false;
  copied: boolean = false;
  randomPageCode: string;

  constructor(private router: Router,
    private activeroute: ActivatedRoute
    ) { }

  ngOnInit() {
    // Get the username from the URL
    const username = this.activeroute.snapshot.params['name'];
    this.invitee = USER_DATA[username];

    if (!this.invitee) {
      alert('Invalid link. Please contact the organizer!');
    }
    this.randomPageCode = this.generateRandomPassword(); 
    this.codeSnippet = `(function() {
        let _keyParts = ['\\x44\\x4d', '\\x57\\x65\\x64\\x64', '\\x69\\x6e\\x67'];
        let _url = window.location.pathname;
        let _segments = _url.split('/');
        let _last = _segments[_segments.length - 1].split('-');
        let _password = _keyParts[0] + _keyParts[1] + _keyParts[2] + "` + this.randomPageCode + `" +_last[_last.length - 1];
        console.log('\\x50\\x61\\x73\\x73\\x77\\x6f\\x72\\x64\\x20\\x46\\x6f\\x75\\x6e\\x64\\x3a ' + _password);
    })();`;
  }

  ngAfterViewInit() {
    $.getScript('assets/js/glanz_script.js');
  }

  public codeSnippet: string;

 // Method to copy the code to clipboard
 copyToClipboard() {
    /*navigator.clipboard.writeText(this.codeSnippet).then(() => {
      this.copied = true; // Show the success message
      setTimeout(() => {
        this.copied = false; // Hide the success message after 3 seconds
      }, 3000);
    }).catch(err => {
      console.error('Error copying text: ', err);
    });*/
  }

  // Track the password entered, its correctness, and whether the medical phase should be shown
  public passwordEntered: string = '';
  public passwordCorrect: boolean = false;
  public showMedicalPhase: boolean = false;
  public showInitialContent: boolean = true;
  password: string = '';
  medicalAnswer: string = '';
  medicalFailed: boolean = false;
  passwordFailed: boolean = false;
  celebrationTriggered: boolean = false;
  tableHint: string = '';
  tableImg: string = '';

  // Track and generate random fun messages on failure
  public failureMessages: string[] = [
    "Oops! That's not it. Try again! 😜",
    "Not quite, but keep going! 😉",
    "Oh no, wrong one! Don't give up! 😂",
    "Engineers dont bite. Well... Atleast some of them.",
    "Hmm, close, but not quite there yet! 😅",
  ];
  public currentFailureMessage: string = '';

  public countryData = {
    "Tanzânia": {
      text: "A tua mesa é onde podes escalar a montanha mais alta de África e fazer um safari inesquecível.",
      img: "assets/images/countries/tanzania.jpg"
    },
    "Marrocos": {
      text: "A tua mesa é um país onde o deserto e os mercados vibrantes dão cor e vida às cidades.",
      img: "assets/images/countries/marrocos.jpg"
    },
    "Qatar": {
      text: "A tua mesa é onde o moderno encontra o tradicional no meio do deserto.",
      img: "assets/images/countries/qatar.jpg"
    },
    "Dinamarca": {
      text: "A tua mesa é a terra das bicicletas e do conceito de hygge, perfeito para um casal feliz.",
      img: "assets/images/countries/dinamarca.jpg"
    },
    "Andorra": {
      text: "A tua mesa é um pequeno paraíso nas montanhas, entre Espanha e França, ideal para esquiadores.",
      img: "assets/images/countries/andorra.jpg"
    },
    "Itália": {
      text: "A tua mesa é onde o amor encontra a melhor pizza e paisagens inesquecíveis.",
      img: "assets/images/countries/italia.jpg"
    },
    "Croácia": {
      text: "A tua mesa é um destino de praias paradisíacas e cidades medievais encantadoras.",
      img: "assets/images/countries/croacia.jpg"
    },
    "África do Sul": {
      text: "A tua mesa é na terra de safaris emocionantes e paisagens deslumbrantes no Cabo da Boa Esperança.",
      img: "assets/images/countries/africa_do_sul.jpg"
    },
    "Espanha": {
      text: "A tua mesa é o país dos tapas, flamenco e um clima que faz lembrar Portugal.",
      img: "assets/images/countries/espanha.jpg"
    },
    "Peru": {
      text: "A tua mesa é o lugar perfeito para explorar e descobrir os segredos dos Incas.",
      img: "assets/images/countries/peru.jpg"
    },
    "Bolívia": {
      text: "A tua mesa é o país onde podes caminhar por paisagens salgadas e perder-te nas estrelas.",
      img: "assets/images/countries/bolivia.jpg"
    },
    "Vietname": {
      text: "A tua mesa é a terra de arrozais verdes, motos e onde foi feito o pedido de casamento.",
      img: "assets/images/countries/vietname.jpg"
    },
    "Brasil": {
      text: "A tua mesa é o país do samba, da alegria e... da noiva!",
      img: "assets/images/countries/brasil.jpg"
    },
    "Portugal": {
      text: "A tua mesa é o país onde o noivo nasceu e onde o amor começou!",
      img: "assets/images/countries/portugal.jpg"
    }
  };
  

  // Engineers and medics data
  public engineers = [
    { name: 'João Carvalho', phone: '+351 913 044 242', photoUrl: 'assets/images/invitees/joaocarvalho.jpg' },
    { name: 'João Mendes', phone: '+351 963 546 315', photoUrl: 'assets/images/invitees/joaomendes.jpg' },
    { name: 'Diogo Matos', phone: '+351 933 277 936', photoUrl: 'assets/images/invitees/diogomatos.jpeg' },
    { name: 'Diogo Correia', phone: '+351 912 861 239', photoUrl: 'assets/images/invitees/diogocorreia.jpeg' },
  ];

  public medics = [
    { name: 'Medic 1', phone: '987-654-3210', photoUrl: 'assets/images/invitees/diogocorreia.jpeg' },
    { name: 'Medic 2', phone: '876-543-2109', photoUrl: 'assets/images/invitees/diogocorreia.jpeg' },
  ];

  // Check password and show the medical phase if correct
  checkPassword() {
    let pathURL =  window.location.pathname.split('-').pop();
    if(pathURL) {
      pathURL = pathURL.substring(1);
      console.log(pathURL);
    }
    const correctPassword = 'DMWedding' + this.randomPageCode + pathURL;
    if (this.passwordEntered === correctPassword || this.debugActive) {
      this.passwordCorrect = true;
      this.showInitialContent = false; // Hide initial content
      this.showMedicalPhase = true; // Show medical phase
    } else {
      this.passwordCorrect = false;
      // Select a random fun failure message
      this.currentFailureMessage = this.failureMessages[Math.floor(Math.random() * this.failureMessages.length)];
    }
  }

  // Validate medical question answer
  validateMedicalAnswer() {
    const userAnswer = this.medicalAnswer.toLowerCase();
    const validAnswers = Array.isArray(this.invitee.answer) 
      ? this.invitee.answer.map(answer => answer.toLowerCase()) 
      : [this.invitee.answer.toLowerCase()];

    if (validAnswers.includes(userAnswer)) {
      this.celebrationTriggered = true;
      this.showMedicalPhase = false;
      this.tableHint = this.countryData[this.invitee.table].text; // Custom table name logic
      this.tableImg = this.countryData[this.invitee.table].img;
      this.medicalFailed = false;

    } else {
      this.medicalFailed = true; // Show failure message
      this.showHints = true; // Display hints on incorrect answers
    }
  }

    generateRandomPassword(): string {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let password = '';
      for (let i = 0; i < 4; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        password += characters[randomIndex];
      }
      return password;
    }


    // Hide the celebration effect after a delay
    hideCelebrationAfterDelay() {
      setTimeout(() => {
        this.celebrationTriggered = false;
      }, 5000); // Celebration lasts for 5 seconds
    }


  // refresh(): void {
  //   window.location.reload();
  // }

}
