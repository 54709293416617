import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css']
})
export class TopNavComponent implements OnInit {

  activeMenu: string | null = null;

  showSubMenu(menu: string): void {
    this.activeMenu = menu;
  }

  hideSubMenu(menu: string): void {
    if (this.activeMenu === menu) {
      this.activeMenu = null;
    }
  }

  constructor(private router: Router) { }

  ngOnInit() {
  }

  // refresh(): void {
  //   window.location.reload();
  // }

}
