import { Component, OnInit, AfterViewInit, ElementRef, Renderer2, } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'app-registry',
  templateUrl: './registry.component.html',
  styleUrls: ['./registry.component.css']
})
export class RegistryComponent implements OnInit, AfterViewInit {
  donationAmount = 100; // Example amount
  iframeUrl = 'https://sites.icasei.com.br/dariomariana';

  
  constructor(private http: HttpClient, private router: Router,
    private renderer: Renderer2, private el: ElementRef
  ) {}

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    $.getScript('assets/js/glanz_script.js');
  }

  removeBars() {
    console.log('Load sucessful');
  }

  // Pix donation handler
  donateViaPix() {
    // Example API request to Pagar.me to create a Pix charge
    this.http.post('https://api.pagar.me/1/charges', {
      amount: this.donationAmount * 100, // Amount in cents
      payment_method: 'pix',  // Set Pix as payment method
      // Any additional configuration you need
    }).subscribe((response: any) => {
      const pixQrCode = response.pix.qr_code;  // Extract the Pix QR code from response
      // Display the QR code to the user or use it in your template
      console.log('Pix QR Code:', pixQrCode);
    });
  }

  // MBWAY donation handler
  donateViaMBWAY() {
    // Example API request to Pagar.me to create an MBWAY charge
    this.http.post('https://api.pagar.me/1/charges', {
      amount: this.donationAmount * 100, // Amount in cents
      payment_method: 'mbway',  // Set MBWAY as payment method
      // Additional configurations
    }).subscribe((response: any) => {
      const mbwayLink = response.mbway.payment_url;  // Extract the MBWAY payment link from response
      // Show the MBWAY link to the user or use it in your template
      console.log('MBWAY Payment URL:', mbwayLink);
    });
  }
}

