import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css']
})
export class InviteComponent implements OnInit, AfterViewInit {

  constructor(private router: Router) { }

  showPage: boolean = false; // To control when the page content is shown

  ngOnInit(): void {}


  ngAfterViewInit() {
    $.getScript('assets/js/glanz_script.js');
  }

}
