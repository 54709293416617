import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HttpModule } from '@angular/http';

import { AppComponent } from './app.component';
import { NgxConfettiModule } from 'ngx-confetti';


import { LandingPageComponent } from './landing-page/landing-page.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { TravelComponent } from './travel/travel.component';
import { EntourageComponent } from './entourage/entourage.component';
import { FaqComponent } from './faq/faq.component';
import { RegistryComponent } from './registry/registry.component';
import { GalleryComponent } from './gallery/gallery.component';
import { SongRequestComponent } from './song-request/song-request.component';
import { SongRequestService } from './services/songRequest.service';
import { TopNavComponent } from './top-nav/top-nav.component';
import { InviteComponent } from './invite/invite.component';
import { HttpClientModule } from '@angular/common/http';
import { HistoryComponent } from './history/history.component';
import { MessagesComponent } from './messages/messages.component';
import { ConfirmPresenceComponent } from './confirm-presence/confirm-presence.component';



const routes: Routes = [
  { path: '', redirectTo: 'index', pathMatch: 'full' },
  { path: 'invite',  component: InviteComponent },
  { path: 'index',  component: LandingPageComponent },
  { path: 'historia',  component: HistoryComponent },
  { path: 'messages',  component: MessagesComponent },
  { path: 'confirm',  component: ConfirmPresenceComponent },
  { path: 'home',  component: LandingPageComponent },
  { path: 'schedule', component: ScheduleComponent},
  { path: 'travel', component: TravelComponent},
  { path: 'entourage', component: EntourageComponent},
  { path: 'faq', component: FaqComponent},
  { path: 'presentes', component: RegistryComponent},
  { path: 'gallery', component: GalleryComponent},
  { path: 'song-request', component: SongRequestComponent},
 // { path: ':name', component: EntourageComponent},

];


@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    ScheduleComponent,
    TravelComponent,
    EntourageComponent,
    SongRequestComponent,
    GalleryComponent,
    FaqComponent,
    TopNavComponent,
    RegistryComponent,
    HistoryComponent,
    MessagesComponent,
    ConfirmPresenceComponent,
    InviteComponent
  ],
  imports: [
    HttpModule,
    HttpClientModule,
    NgxConfettiModule,
    BrowserModule,
    FormsModule,
    RouterModule.forRoot(routes)
  ],
  providers: [SongRequestService],
  bootstrap: [AppComponent]
})
export class AppModule { }
